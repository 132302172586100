import React, { useRef, useState } from "react"

import {
  Button,
  Container,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import Book from "public/assets/landing_page/teachersMonth/Book.webp"
import HappyTeachersDay from "public/assets/landing_page/teachersMonth/happyTeachersMonth.webp"
import Heart from "public/assets/landing_page/teachersMonth/Heart.webp"
import SuraasaPlusLogo from "public/assets/landing_page/teachersMonth/suraasaPlusLogo.webp"
import TeachersMonthTitleMobile from "public/assets/landing_page/teachersMonth/worldTeachersMonth-mobile.json"
import TeachersMonthTitleWeb from "public/assets/landing_page/teachersMonth/worldTeachersMonth-web.json"
import { createUseStyles } from "react-jss"
// import TeachersMonthTitleWeb1 from "base-placebo/public/assets/landing_page/teachersMonth/teachersMonthTitleWeb.lottie"
import Lottie from "react-lottie"

import { Play } from "iconoir-react"

import GradientButton from "components/ito/2023/landingPage/GradientButton"
import { getPlatformURL } from "utils/helpers"

const getClaimURL = () => {
  if (typeof window === "undefined")
    return getPlatformURL("learn", `/claim-suraasa-plus`)

  return getPlatformURL(
    "learn",
    `/claim-suraasa-plus?utm_source=landing-page-hero`
  )
}

const useStyles = createUseStyles(styleTheme => ({
  playBtn: {
    animation: "$pulse 1.5s ease-in-out infinite",
    [styleTheme.breakpoints.down("sm")]: {
      animation: "$pulse-tab 1.5s ease-in-out infinite",
    },
    [styleTheme.breakpoints.down("xs")]: {
      animation: "$pulse-mobile 1.5s ease-in-out infinite",
    },
  },

  "@keyframes pulse": {
    "0%": {
      boxShadow: "0 0 0 0px rgba(24, 24, 24, 0.75)",
    },
    "100%": {
      boxShadow: "0 0 0 24px rgba(24, 24, 24, 0)",
    },
  },
  "@keyframes pulse-tab": {
    "0%": {
      boxShadow: "0 0 0 0px rgba(24, 24, 24, 0.75)",
    },
    "100%": {
      boxShadow: "0 0 0 18px rgba(24, 24, 24, 0)",
    },
  },
  "@keyframes pulse-mobile": {
    "0%": {
      boxShadow: "0 0 0 0px rgba(24, 24, 24, 0.75)",
    },
    "100%": {
      boxShadow: "0 0 0 12px rgba(24, 24, 24, 0)",
    },
  },
}))

const videoURL =
  "https://assets.suraasa.com/website-assets/Suraasa%20Plus%20Launch%20v12.mp4"

const HappyTeachersMonth = () => {
  const classes = useStyles()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play()
        setIsPlaying(true)
      } else {
        videoRef.current.pause()
        setIsPlaying(false)
      }
    }
  }

  return (
    <div className="py-8 bg-white px-0.5 sm:px-0">
      <Container>
        <div>
          <div className="relative">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: isXsDown
                  ? TeachersMonthTitleMobile
                  : TeachersMonthTitleWeb,
              }}
              speed={1}
              style={{
                userSelect: "none",
                cursor: "default",
                height: "100%",
                width: "100%",
              }}
              isClickToPauseDisabled
            />
            <Image
              alt="heart"
              className="left-0 top-1 sm:top-auto size-5 sm:size-8 md:size-9 absolute sm:-bottom-9 sm:-left-3"
              src={Heart}
            />
          </div>
          <Typography
            className="text-center max-w-[424px] md:max-w-[598px] mx-auto !text-[#121212] mt-2 relative"
            variant="subtitle1"
          >
            Because one day isn’t enough to celebrate the amazing work you do
            every day!
            <Image
              alt="book"
              className="w-4 h-3 right-0 -bottom-2.75 sm:w-5 sm:h-4  md:w-8 md:h-5 absolute sm:-right-8 md:-right-14 sm:-bottom-4"
              src={Book}
            />
          </Typography>
          <div className="mt-3 flex items-center justify-center sm:flex-row gap-1 flex-col">
            <GradientButton
              className="!text-lg !font-medium !leading-5"
              component="a"
              fullWidth={isXsDown}
              href={getClaimURL()}
            >
              Get{" "}
              <Image
                alt="SuraasaPlusLogo"
                className=" h-[19.64px] object-contain w-fit -mt-0.75 mx-0.5"
                src={SuraasaPlusLogo}
              />
              {"   "}for Free
            </GradientButton>
            <Button
              color="black"
              component="a"
              fullWidth={isXsDown}
              href="https://www.suraasa.com/suraasa-plus"
              variant="outlined"
              rounded
            >
              Learn More
            </Button>
          </div>
          <div className="overflow-hidden rounded-xl border-4 sm:rounded-3xl sm:border-8 border-onSurface-200 mt-4 sm:mt-6 mx-auto relative">
            {!isPlaying && (
              <div className="absolute size-full top-0 left-0 grid place-items-center z-[4] bg-black/25">
                <button
                  className={clsx(
                    "size-6 sm:size-8 md:size-[119px] bg-black/75 rounded-full grid place-items-center z-[2] cursor-pointer",
                    classes.playBtn
                  )}
                  onClick={togglePlayPause}
                >
                  <Play className="text-white fill-white size-4 sm:size-5 md:size-[75px] ml-0.5 sm:ml-0.75 md:ml-1" />
                </button>
              </div>
            )}
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              controls={isPlaying}
              controlsList="nodownload noremoteplayback noplaybackrate"
              poster={HappyTeachersDay.src}
              ref={videoRef}
              src={videoURL}
              width="100%"
              disablePictureInPicture
              onPause={() => {
                setIsPlaying(false)
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HappyTeachersMonth
