import { useEffect, useRef } from "react"

import Glide from "@glidejs/glide"
import { Button, Container, Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import AiSymbol from "public/assets/landing_page/aitTools/AiSymbol.svg"
import ProAiTools from "public/assets/landing_page/aitTools/suraasaProToolsLottie.json"
import { useTheme } from "react-jss"
import Lottie from "react-lottie"

import { ArrowRightCircle, ArrowRightCircleSolid } from "iconoir-react"

import { getPlatformURL } from "utils/helpers"

import Card, { CardProps } from "./Card"
import Rating from "./Rating"

const Item = ({ title }: { title: string }) => (
  <div className="flex items-center gap-1">
    <Image alt="ait-symbol" className="shrink-0" src={AiSymbol} />
    <Typography variant="largeBody">{title}</Typography>
  </div>
)
const AiTools = () => {
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme<Theme>()
  const itemList = [
    "Matches with your school's books & curriculum",
    "Easy to use even if you are not tech savvy - as simple as filling out a Google Form!",
    "Downloadable for further use or editing in Microsoft Word / Google Doc",
  ]

  const cardDetails: CardProps[] = [
    {
      link: getPlatformURL("learn", "/ai-tools/lesson-plan/"),
      title: "Lesson Plan",
      subTitle: "Generate engaging lesson plans in seconds",
      class: "bg-[#E5A256]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#E5A256_0,transparent_70%)]",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/unit-planner/"),
      title: "Unit Plan",
      subTitle: "Draft comprehensive unit plans faster than ever",
      class: "bg-[#E478B5]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#E478B5_0,transparent_70%)]",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/quiz/"),
      title: "Quiz",
      subTitle: "Generate accurate quizzes that enhance student learning",
      class: "bg-[#97BC62]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#97BC62_0,transparent_70%)]",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/assignment/"),
      title: "Assignment",
      subTitle: "Generate accurate quizzes that enhance student learning",
      class: "bg-[#EEC239]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#EEC239_0,transparent_70%)]",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/rubrics/"),
      title: "Rubrics",
      subTitle: "Generate custom rubrics for precise assignment evaluation",
      class: "bg-[#49B7CC]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#49B7CC_0,transparent_70%)]",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/subjective-assessment/"),
      title: "Subjective Assessment",
      subTitle: "Generate highly relevant subjective assessments",
      class: "bg-[#9A7BBA]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#9A7BBA_0,transparent_70%)]",
      btnLabel: "Generate Assessment",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/activity-planner/"),
      title: "Activity",
      subTitle: "Plan engaging classroom activities easily",
      class: "bg-[#FEAE8D]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#FEAE8D_0,transparent_70%)]",
      btnLabel: "Create Activity",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/handout/"),
      title: "Handout",
      subTitle: "Generate informative lesson handouts effortlessly",
      class: "bg-[#3B82F6]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#3B82F6_0,transparent_70%)]",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/narration/"),
      title: "Narration",
      subTitle: "Transform your lesson plans into engaging narratives",
      class: "bg-[#138D75]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#138D75_0,transparent_70%)]",
    },
    {
      link: getPlatformURL("learn", "/ai-tools/video-summary/"),
      title: "YouTube Video Summary",
      subTitle: "Generate concise YouTube video summaries in seconds",
      class: "bg-[#CF4747]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#CF4747_0,transparent_70%)]",
      btnLabel: "Generate Summary",
    },
    {
      link: getPlatformURL("learn", "/profile/?action=generateCoverLetter"),
      title: "Cover Letter",
      subTitle: "Grab recruiters’ attention with customized cover letters",
      class: "bg-[#496AC9]",
      cardClass:
        "sm:hover:bg-[radial-gradient(circle_at_40%_100%,#496AC9_0,transparent_70%)]",
    },
  ]

  useEffect(() => {
    if (sliderRef.current) {
      const glide = new Glide("#aiTools_slider", {
        type: "slider",
        gap: 16,
        focusAt: 0,
        bound: true,
        rewind: false,
        peek: { before: 0, after: 0 },
        perView: 5.5,
        breakpoints: {
          2560: {
            perView: 5.5,
          },
          [theme.breakpoints.values.xl]: {
            perView: 4,
          },
          [theme.breakpoints.values.lg]: {
            perView: 3.5,
          },
          [theme.breakpoints.values.md]: {
            perView: 2,
          },
          [theme.breakpoints.values.sm]: {
            perView: 1.5,
          },
          [theme.breakpoints.values.xs]: {
            perView: 1.25,
          },
        },
      }).mount()

      return () => {
        glide.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderRef.current])

  return (
    <div
      className={clsx(
        "bg-onSurface-900 py-6 sm:py-8 md:py-12 !text-white overflow-hidden relative",
        "md:bg-[radial-gradient(circle_at_-30%_5%,rgb(250,146,0,0.5)_0px,transparent_50%),radial-gradient(circle_at_-30%_20%,rgb(250,150,0,0.05)_0px,transparent_40%),radial-gradient(circle_at_-30%_30%,rgb(233,164,1,0.05)_0px,transparent_40%),radial-gradient(circle_at_-30%_31%,rgb(170,184,20,0.3)_0px,transparent_40%),radial-gradient(circle_at_-30%_40%,rgb(132,178,56,0.3)_0px,transparent_40%),radial-gradient(circle_at_-30%_50%,rgb(126,180,65,0.3)_0px,transparent_40%),radial-gradient(circle_at_-30%_60%,rgb(7,192,158,0.3)_0px,transparent_40%),radial-gradient(circle_at_-30%_90%,rgb(68,115,243,0.3)_0px,transparent_40%),radial-gradient(circle_at_-30%_100%,rgb(132,178,56,0.75)_0px,transparent_50%)]",
        "bg-[radial-gradient(circle_at_100%_-40%,rgb(250,146,0,0.5)_0px,transparent_50%),radial-gradient(circle_at_90%_-40%,rgb(250,150,0,0.05)_0px,transparent_40%),radial-gradient(circle_at_60%_-40%,rgb(233,164,1,0.05)_0px,transparent_40%),radial-gradient(circle_at_50%_-40%,rgb(170,184,20,0.3)_0px,transparent_40%),radial-gradient(circle_at_40%_-40%,rgb(132,178,56,0.3)_0px,transparent_40%),radial-gradient(circle_at_31%_-40%,rgb(126,180,65,0.3)_0px,transparent_40%),radial-gradient(circle_at_30%_-40%,rgb(7,192,158,0.3)_0px,transparent_40%),radial-gradient(circle_at_20%_-40%,rgb(68,115,243,0.3)_0px,transparent_40%),radial-gradient(circle_at_5%_-40%,rgb(132,178,56,0.75)_0px,transparent_50%)]"
      )}
    >
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          button .aiTools-white {
             display: none;
          }
          button .aiTools-black {
             display: block;
          }
          .glide__arrow--disabled .aiTools-white {
             display: block;
          }
          .glide__arrow--disabled .aiTools-black {
             display: none;
          }
          `,
        }}
      />
      <Container>
        <div className="max-w-[700px] w-full">
          <Typography
            className="mb-2 text-center sm:text-start"
            variant="preTitle"
          >
            Free AI Tools for Teachers
          </Typography>
          <Typography
            className="mb-3 text-center sm:text-start !hidden lg:!block"
            variant="largeTitle"
          >
            Save 4 Hours Every Day Using AI!
          </Typography>
          <Typography
            className="mb-3 text-center sm:text-start lg:!hidden !block"
            variant="title1"
          >
            Save 4 Hours Every Day Using AI!
          </Typography>
          <div className="flex flex-col gap-2 mb-6">
            {itemList.map(item => (
              <Item key={item} title={item} />
            ))}
          </div>
          <div className="flex items-center gap-2 mb-7 flex-col sm:flex-row">
            <Button
              className="!w-full sm:!w-max"
              component={Link}
              href={getPlatformURL("learn", "/ai-tools?tab=All")}
            >
              Save my Time with AI
            </Button>
            <Rating />
          </div>
        </div>
      </Container>

      <div className="hidden md:flex h-fit xl:!w-[750px] [@media_(min-width:_1410px)]:w-[550px] w-[350px] flex-col absolute right-0 top-6 sm:top-8 md:top-12 text-surface sm:w-[700px] md:w-[370px] overflow-hidden rounded-[19.74px_0px_0px] border-[1.23px] border-solid border-[rgba(255,255,255,0.25)] border-r-0 border-b-0 z-2">
        <div className="relative">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: ProAiTools,
            }}
            speed={1}
            style={{
              userSelect: "none",
              cursor: "default",
              height: "100%",
              width: "100%",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingBottom: "32px",
            }}
            isClickToPauseDisabled
          />
          <div className="w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,rgba(255,255,255,0.25)_0%,rgba(255,255,255,0)_100%)]" />
        </div>
      </div>
      <div className="relative" id="aiTools_slider" ref={sliderRef}>
        <Container>
          <div className="mt-4 glide__track" data-glide-el="track">
            <div className="flex glide__slides">
              {cardDetails.map(item => (
                <Card data={item} key={item.title} />
              ))}
            </div>
          </div>
          <div className="flex justify-start mt-4" data-glide-el="controls">
            <button
              className="mr-1 rotate-180 group"
              data-glide-dir="<"
              id="left"
            >
              <ArrowRightCircle className="size-5 aiTools-white" />
              <ArrowRightCircleSolid className="size-5 aiTools-black" />
            </button>
            <button className="group" data-glide-dir=">" id="right">
              <ArrowRightCircle className="size-5 aiTools-white" />
              <ArrowRightCircleSolid className="size-5 aiTools-black" />
            </button>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default AiTools
