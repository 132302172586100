import { useState } from "react"

import {
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { motion } from "framer-motion"
import Image from "next/image"
import { createUseStyles, useTheme } from "react-jss"

import { NavArrowLeft, NavArrowRight } from "iconoir-react"

import { useGradientStyle } from "components/shared/GradientTypography"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "white",
  },
  section: {
    marginTop: 96,
    display: "flex",
    gap: theme.spacing(2.5),
    justifyContent: "space-between",
    flexDirection: "row",

    [theme.breakpoints.down("xl")]: {
      justifyContent: "flex-start",
    },
    "@media only screen and (max-width: 687px)": {
      flexDirection: "column",
    },
  },

  iconButton: {
    backgroundColor: `${theme.colors.onSurface[200]} !important`,
    borderRadius: "50% !important",
  },
  slidingContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    paddingBottom: "3px",
  },
  navigationButtons: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      marginTop: 16,
      display: "block",
    },
  },
  textContainer: {
    maxWidth: 376,
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: 520,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 316,
    },
    "@media only screen and (max-width: 687px)": {
      maxWidth: "none",
    },
  },
  whiteShade: {
    height: "100%",
    position: "absolute",
    right: 0,
    background:
      "linear-gradient(270deg, #FFFF 28.78%, rgba(255, 255, 255, 0.8) 64.72%, rgba(255, 255, 255, 0) 93.16%)",
    width: "30%",
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  certificateImg: {
    height: 390,

    [theme.breakpoints.down("sm")]: { height: 340 },
  },
  customGradientDivider: {
    height: 2,
    width: "100%",
    borderRadius: "20px",
  },
}))

export const CustomDivider = ({
  className,
  gradient,
}: {
  className?: string
  gradient?: string
}) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.customGradientDivider, className)}
      style={{
        background: gradient,
      }}
    />
  )
}

const animation = [
  [{ scale: 1, opacity: 1 }, { x: "0%" }, { x: "0%" }],
  [
    { scale: 0.45, opacity: 0 },
    { x: "calc(-100% - 16px)" },
    { x: "calc(-100% - 16px)" },
  ],
  [
    { scale: 0.35, opacity: 0 },
    { x: "calc(-100% - 16px)", scale: 0.85 },
    { x: "calc(-200% - 24px)" },
  ],
]
const numberOfAnimations = animation.length

const ChooseYourCertificate = () => {
  const classes = useStyles()

  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [animateIndex, setAnimateIndex] = useState(0)

  const nextAnimation = () => {
    if (animateIndex === numberOfAnimations - 1) {
      return
    }
    setAnimateIndex(s => (s += 1))
  }
  const prevAnimation = () => {
    if (animateIndex === 0) {
      return
    }
    setAnimateIndex(s => (s -= 1))
  }

  const gradient = useGradientStyle(
    "linear-gradient(90deg, #49B7CC 0%, #97BC62 100%)"
  )

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <div className={classes.textContainer}>
          <Typography
            className="mb-2"
            style={{ maxWidth: 376 }}
            variant="title1"
          >
            Choose your <span style={gradient}>Certificates</span> on Completion
          </Typography>

          <Typography variant="largeBody">
            Spending years to get multiple certifications is the old way. The
            new way? Study once, then choose multiple certifications based on
            which one suits your goals.
            <br />
            <br />
            With Suraasa, you get to choose from certifications like ATHE UK
            Level 6 Diploma in Teaching, Master’s in Education by International
            Teachers University (USA), and Professional Certificate in Teaching
            & Learning.
          </Typography>
        </div>
        <div className={clsx(classes.slidingContainer, "gap-x-2")}>
          {[
            "QualificationCertificate.webp",
            "ATHECertificate.webp",
            "ITU Degree.webp",
          ].map((item, index) => (
            <div className="shrink-0" key={item}>
              <div>
                <motion.img
                  alt={item}
                  animate={animation[animateIndex][index]}
                  className={clsx(
                    classes.certificateImg,
                    "shadow-[0px_2.9px_9.08px_0px_#00000026] border-onSurface-200 border-[0.36px] border-solid rounded-lg"
                  )}
                  src={`/assets/landing_page/v2/certificates/${item}`}
                  transition={{
                    damping: 0,
                  }}
                />
              </div>
              <div className={classes.navigationButtons}>
                {index === 0 ? (
                  <div className="flex flex-row items-center justify-center gap-2">
                    <IconButton
                      className={classes.iconButton}
                      color="white"
                      size="md"
                      variant="filled"
                      onClick={prevAnimation}
                    >
                      <NavArrowLeft height="28" width="28" />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      color="white"
                      size="md"
                      variant="filled"
                      onClick={nextAnimation}
                    >
                      <NavArrowRight height="28" width="28" />
                    </IconButton>
                  </div>
                ) : (
                  <div style={{ height: 44, width: "100%" }} />
                )}
              </div>
            </div>
          ))}
          <div className={classes.whiteShade} />
        </div>
      </div>

      <div>
        {!isXsDown && (
          <CustomDivider
            className="mt-3 mb-3"
            gradient="linear-gradient(90deg, #49B7CC 0%, #97BC62 100%)"
          />
        )}

        <div className="flex flex-col items-start justify-between gap-2 md:items-center sm:flex-row">
          <Image
            alt="icon"
            height={48}
            src="/assets/landing_page/v2/magic-happens/certificate.webp"
            width={48}
          />
          <Typography style={{ maxWidth: 366 }} variant="subtitle2">
            All certifications are benchmarked to professional teacher skill
            standards of world’s leading education systems.
          </Typography>
        </div>
        {isXsDown && (
          <CustomDivider
            className="mt-3"
            gradient="linear-gradient(90deg, #49B7CC 0%, #97BC62 100%)"
          />
        )}
      </div>
    </div>
  )
}

export default ChooseYourCertificate
