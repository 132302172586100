import React from "react"

import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import Teachers from "public/assets/landing_page/aitTools/teachers.webp"

import { Star } from "iconoir-react"

const Rating = ({ className }: { className?: string }) => {
  const svgProps = { width: "15px", height: "15px" }
  return (
    <div className={clsx("flex items-center w-fit ", className)}>
      <Image
        alt="review-photo"
        className={clsx("mr-1 shrink-0 w-[60px] h-[32px]")}
        src={Teachers}
      />

      <div>
        <div className="flex gap-0.5">
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <div className="relative">
            <Star {...svgProps} className="text-[#E2E8F0]" fill="#E2E8F0" />
            <Star
              {...svgProps}
              className={clsx("absolute top-0 left-0 text-warning-400")}
              fill="#EEC239"
              style={{ clipPath: "polygon(0% 0%,50% 0%,50% 100%,0% 100%)" }}
            />
          </div>
        </div>
        <Typography className="!text-white !text-sm !font-normal !leading-[16.94px] !text-left">
          Rated 4.78/5 by teachers globally
        </Typography>
      </div>
    </div>
  )
}

export default Rating
