import React from "react"

import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import AiSymbol from "public/assets/landing_page/aitTools/AiSymbol.svg"

import { ArrowRight } from "iconoir-react"

export type CardProps = {
  link: string
  title: string
  subTitle: string
  class: string
  cardClass: string
  btnLabel?: string
}
const Card = ({ data }: { data: CardProps }) => (
  <Link
    className={clsx(
      "p-2.25 rounded-lg bg-white h-[320px] flex flex-col justify-between group transition-all duration-300 shrink-0 cursor-pointer",
      data.cardClass
    )}
    href={data.link}
  >
    <div>
      <div
        className={clsx(
          "rounded-xl p-1.25 w-fit mb-1 shadow-[1px_1px_5px_0px_#0000001A]",
          data.class
        )}
      >
        <Image alt="ait-symbol" className="shrink-0" src={AiSymbol} />
      </div>
      <span className="text-[22px] font-bold leading-[24.2px] tracking-[-0.06em] text-left mb-0.5 text-onSurface-900">
        {data.title} Generator
      </span>
      <Typography className="!text-onSurface-500" variant="smallBody">
        {data.subTitle}
      </Typography>
    </div>
    <Link
      className={clsx(
        "px-1.5 py-1 rounded-full w-fit group-hover:bg-onSurface-900 transition-all flex items-center flex-row group-hover:w-full justify-between duration-300",
        data.class
      )}
      href={data.link}
    >
      <Typography className="shrink-0" variant="button">
        {data.btnLabel || `Generate ${data.title}`}
      </Typography>
      <ArrowRight className="text-white opacity-0 size-0 group-hover:opacity-100 group-hover:size-2 transition-all " />
    </Link>
  </Link>
)

export default Card
